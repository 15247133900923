﻿* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.interactive * {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

input {
    -webkit-user-select: auto !important;
}

input[type=text]::-ms-clear {
    display: none;
}


@font-face {
    font-family: "ISL fontfamily";
    src: url("../../Content/isl.eot");
    src: url("../../Content/isl.eot#iefix") format("embedded-opentype"), url("../../Content/isl.ttf") format("truetype");
}


@font-face {
    font-family: "Trueview";
    src: url("'../../Content/trueview icon set1.eot");
    src: url("../../Content/trueview icon set1.eot#iefix") format("embedded-opentype"), url("../../Content/trueview icon set1.ttf") format("truetype");
}

i[class*="win-icon-custom"]:before {
    font-family: "ISL fontfamily";
    overflow: visible !important;
}

.win-icon-custom-isl:before {
    content: "\E110";
}


i[class*="win-icon-trueview"]:before {
    font-family: "Trueview";
    overflow: visible !important;
}

i[class*="win-icon-trueview"] {
    overflow: visible !important;
}


.win-icon-trueview-completed:before {
    content: "\E020";
    color: green;
}

.win-icon-trueview-retired:before {
    content: "\E021";
    color: red;
}

.win-icon-trueview-suspended:before {
    content: "\E022";
    color: yellow;
}

.win-icon-trueview-lock:before {
    content: "\E060";
}


.win-listitem {
    padding: 10px;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.win-listitem.win-listitem-filled {
    background-color: rgba(0, 0, 0, 0.10);
}

.win-listitem .win-listview-item-button {
    position: absolute;
    right: 0;
    top: 0;
}


.x-small .win-hub .win-surface.win-gridlayout.win-structuralnodes,
.small .win-hub .win-surface.win-gridlayout.win-structuralnodes {
    height: 365px !important;
    padding-left: 15px;
}


.x-small .win-listview .win-vertical .win-gridlayout.win-surface,
.small .win-listview .win-vertical .win-gridlayout.win-surface {
    margin-left: 0px !important;
}


.x-small [data-panel="template"],
.small [data-panel="template"] {
    position: fixed;
    right: 0;
}


.printer {
    display: none;
}

.clickable {
    cursor: pointer;
}

@media print {

    .app {
        display: none;
    }

    .printer {
        display: block;
    }
}

meter[name="PasswordMeter"] {
    width: 100%;
    height: 0.5em;
    background: none rgba(0, 0, 0, 0.1);
}

meter[name="PasswordMeter"]::-webkit-meter-bar {
    background: none;
    background-color: rgba(0, 0, 0, 0.1);
}

meter[name="PasswordMeter"][value="1"]::-webkit-meter-optimum-value { background: red; }
meter[name="PasswordMeter"][value="2"]::-webkit-meter-optimum-value { background: orange; }
meter[name="PasswordMeter"][value="3"]::-webkit-meter-optimum-value { background: green; }

meter[name="PasswordMeter"][value="1"]::-moz-meter-bar { background: red; }
meter[name="PasswordMeter"][value="2"]::-moz-meter-bar { background: orange; }
meter[name="PasswordMeter"][value="3"]::-moz-meter-bar { background: green; }



.win-listitem-detail {
    width: ~"calc(100% - 10px)"; /* Remove scrollbar covering */
}

.truncate {
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}




// TODO: REMOVE ADD TO MODS
.win-icon-custom-icon {
    background: url("../../Plugins/IconDashboard/Content/iconlogoicon.png") no-repeat, url("../../Plugins/Dashboard/Content/iconlogoicon.png") no-repeat;
    background-size: contain;
}

.win-icon-custom-mymission {
    background: url("../../Plugins/IconDashboard/Content/mymissioncx2.png") no-repeat, url("../../Plugins/IconLinks/Content/mymissioncx2.png") no-repeat;
    background-size: contain;
}

.win-icon-custom-missioncx {
    background: url("../../Plugins/IconDashboard/Content/missioncx.png") no-repeat, url("../../Plugins/IconLinks/Content/missioncx.png") no-repeat;
    background-size: contain;
}